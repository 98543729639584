export const customFooterTestData = {
  categories: [
    {
      title: "About Us",
      links: [
        {
          url: "www.example.com",
          text: "Our Mission"
        },
        {
          url: "/team-leaders",
          text: "Our Leadership"
        },
        {
          url: "www.example.com",
          text: "Company Background"
        }
      ]
    },
    {
      title: "Providers",
      titleLink: "/providers",
      links: [
        {
          url: "/become-a-provider",
          text: "Become a provider"
        },
      ]
    },
    {
      title: "Blog",
    }
  ]

}

export const states = [
  {name: "Alabama", abbrev: "AL"},
  {name: "Alaska", abbrev: "AK"},
  {name: "American Samoa", abbrev: "AS"},
  {name: "Arizona", abbrev: "AZ"},
  {name: "Arkansas", abbrev: "AR"},
  {name: "California", abbrev: "CA"},
  {name: "Colorado", abbrev: "CO"},
  {name: "Connecticut", abbrev: "CT"},
  {name: "Delaware", abbrev: "AL"},
  {name: "District Of Columbia", abbrev: "DC"},
  {name: "Federated States Of Micronesia", abbrev: "FM"},
  {name: "Florida", abbrev: "FL"},
  {name: "Georgia", abbrev: "GA"},
  {name: "Guam", abbrev: "GU"},
  {name: "Hawaii", abbrev: "HI"},
  {name: "Idaho", abbrev: "ID"},
  {name: "Illinois", abbrev: "IL"},
  {name: "Indiana", abbrev: "IN"},
  {name: "Iowa", abbrev: "IA"},
  {name: "Kansas", abbrev: "KS"},
  {name: "Kentucky", abbrev: "KY"},
  {name: "Louisiana", abbrev: "LA"},
  {name: "Maine", abbrev: "ME"},
  {name: "Marshall Islands", abbrev: "MH"},
  {name: "Maryland", abbrev: "MD"},
  {name: "Massachusetts", abbrev: "MA"},
  {name: "Michigan", abbrev: "MI"},
  {name: "Minnesota", abbrev: "MN"},
  {name: "Mississippi", abbrev: "MS"},
  {name: "Missouri", abbrev: "MO"},
  {name: "Montana", abbrev: "MT"},
  {name: "Nebraska", abbrev: "NE"},
  {name: "Nevada", abbrev: "NV"},
  {name: "New Hampshire", abbrev: "NH"},
  {name: "New Jersey", abbrev: "NJ"},
  {name: "New Mexico", abbrev: "NM"},
  {name: "New York", abbrev: "NY"},
  {name: "North Carolina", abbrev: "NC"},
  {name: "North Dakota", abbrev: "ND"},
  {name: "Northern Mariana Islands", abbrev: "MP"},
  {name: "Ohio", abbrev: "OH"},
  {name: "Oklahoma", abbrev: "OK"},
  {name: "Oregon", abbrev: "OR"},
  {name: "Palau", abbrev: "PW"},
  {name: "Pennsylvania", abbrev: "PA"},
  {name: "Puerto Rico", abbrev: "PR"},
  {name: "Rhode Island", abbrev: "RI"},
  {name: "South Carolina", abbrev: "SC"},
  {name: "South Dakota", abbrev: "SD"},
  {name: "Tennessee", abbrev: "TN"},
  {name: "Texas", abbrev: "TX"},
  {name: "Utah", abbrev: "UT"},
  {name: "Vermont", abbrev: "VT"},
  {name: "Virgin Islands", abbrev: "VI"},
  {name: "Virginia", abbrev: "VA"},
  {name: "Washington", abbrev: "WA"},
  {name: "West Virginia", abbrev: "WV"},
  {name: "Wisconsin", abbrev: "WI"},
  {name: "Wyoming", abbrev: "WY"},
]
