import React, { useState } from "react"

import Grid from "@material-ui/core/Grid"
import { createStyles, makeStyles, Typography } from "@material-ui/core"
import { Link } from "gatsby"
import Card from "@material-ui/core/Card"
import {states} from "../constants/Mocks"
import Colors from "../constants/Colors"
import CircleProfileCard from "./CircleProfileCard"
import { container } from "../vendor/mui-pro/src/assets/jss/material-kit-pro-react"
import { standardPadding } from "../constants/Spacing"
import clsx from "clsx"
import { graphql, useStaticQuery } from "gatsby"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import {useMediaQuery} from "@material-ui/core"
import useTheme from "@material-ui/styles/useTheme"
import Button from "../vendor/mui-pro/src/components/CustomButtons/Button"
import { getDirections, jumpToSection, launch88 } from "../hooks/routing"
import FullWidthImageText from "./widgets/FullWidthImageText"
import { contentContainerStyles } from "./widgets/ContentContainer"


const useStyles = makeStyles((theme) => createStyles({
  root: {
    backgroundColor: Colors.pickledBluewood,
    paddingBottom: "46px",
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    }
  },
  circle: {
    backgroundColor: Colors.grayChateau,
    borderRadius: "50%",
    width: "65px",
    height: "65px",
    display: "flex",
    marginRight: 12,
    flexShrink: 0,
    [theme.breakpoints.down('xs')]: {
      width: "40px",
      height: "40px",
      marginBottom: 0,

    }
  },
  headerText: {
    marginBottom: "0px !important",
    color: Colors.tundora,
  },
  selectRoot: {
    marginRight: 24,
    borderBottom: "none",
    marginTop: 0,
    backgroundColor: Colors.tulipTree,
    borderRadius: 30,
    paddingTop: 12,
    paddingRight: 38,
    paddingBottom: 12,
    paddingLeft: 28,
    color: Colors.white,
    "svg": {
      transform: `translate(-28px, 1px)`,
      color: Colors.white,
    },
    "&:focus": {
      borderRadius: 30,
      backgroundColor: Colors.tulipTree,
    }
  },
  buttonDiv: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      marginBottom: 32,
    }
  },
  circleInner: {
    fontSize: "28px",
    fontWeight: "900",
    color: Colors.white,
    margin: "auto",
    textAlign: "center",
    fontFamily: "Lato, Verdana, Arial, sans-serif",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px"
    }
  },
  card: {
    ...container,
    transform: "translateY(-70px)",
    paddingTop: 50,
    paddingBottom: "44px",
    marginBottom: "-28px",
    [theme.breakpoints.up('md')]: {
      maxWidth: 860,
    },
    [theme.breakpoints.up("sm")]: {
      ...standardPadding.desktop
    },
    [theme.breakpoints.down("xs")]: {
      ...standardPadding.mobile
    },
  },
  titleField: {
    display: "flex",
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 0,
    }
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 24,
    [theme.breakpoints.down('sm')]: {
      flexWrap: "wrap",
      marginBottom: 20,
    },

  },
  titleSpan: {
    gridArea: "titleSpan",
  },
  cardField: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "18px",
    "&>:nth-child(2)": {
      marginLeft: "10px",
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "80%",
    },
  },
  htmlCardField: {
    "& p": {
      marginBottom: 5,
    }
  },
  grid: {
    [theme.breakpoints.up("sm")]: {
      marginBottom: 15,
    },
  },
  gridEnd:{
    [theme.breakpoints.down("xs")]:{
      marginBottom: 20,
    }
  },
  teamCardGrid: {
    ...container,
    paddingLeft: 0,
    paddingRight: 0,
    justifyContent: "center"
  },
  cardGridContainer: {
    '& div div:nth-child(2)': {
      paddingBottom: "0.5rem",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "30px"
    }
  },
  teamCardImage: {
    width: "150px !important",
    height: "150px",
  },
  teamCardImageContainer: {
    width: "fit-content",
    margin: "auto",
    height: "150px",
    borderRadius: "50%",
    overflow: "hidden",
  },
  icon: {
    color: Colors.loblolly, fontSize: "28px", maxWidth: "24px",
  },
  fs1: {
    fontSize: "32px",
  },
  removeLastMarginBottom: {
    "& .profile_card_list:last-of-type": {
      marginBottom: 0
    }
  },
  popMenuBase: {
    "& li": {
      color: Colors.azure,
    },
  },
}));

export default function ClinicDetailSection({clinic, withButton}){
  const classes = useStyles();
  const contentContainerClasses = contentContainerStyles();
  const [contactSelection, setContactSelection] = useState("none")
  const data = useStaticQuery(graphql`
    query TeamMembersQuery {
      allDatoCmsTeamMember {
        edges {
          node {
            ...teamMembers
          }
        }
      }
    }
  `)

  const mappedTeamMembers = data.allDatoCmsTeamMember.edges.flatMap(e => e.node);
  const clinicTeamMembers = mappedTeamMembers.filter(tM => tM.clinic && tM.clinic.id === clinic.id || false);
  const theme = useTheme();
  const smallBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));

  function handleChange(e) {
    if(e.target.value === "call"){
      window.open(`tel:${clinic.phone}`)
    } else if (e.target.value === "chat"){
      launch88();
    } else if (e.target.value === "back"){
      setTimeout(() => {
        jumpToSection("#ContactForm", smallBreakpoint)
      }, 100)
    }

  }

  const renderButtonSection = () => {
    return(
    <div className={classes.buttonDiv}>
      <FormControl>
        <Select
          value={contactSelection}
          disableUnderline
          className="clinic-detail-select"
          classes={{
            root: classes.selectRoot,
            icon: classes.dropdownIcon
          }}
          MenuProps={{
            PaperProps: {
              className: classes.popMenuBase,
            },
          }}
          onChange={handleChange}
          renderValue={() => (
              <Typography variant="button" style={{marginRight: 10}}>
                Contact us
              </Typography>
          )}
        >
          <MenuItem value="call">
            <Typography variant="button">
              Call us
            </Typography>
          </MenuItem>
          <MenuItem value="back">
            <Typography variant="button">
              Request a call back
            </Typography>
          </MenuItem>
        </Select>
      </FormControl>
      <Button onClick={() => getDirections(clinic.address)}  size="sm" color="secondary">
        <Typography variant="button">
          Get directions
        </Typography>
      </Button>
    </div>
    )
  }

  const stateAbbreviation = () => {
    const selectedState = states.filter(state => state.name === clinic.state)[0]
    const enteredAbbreviation = states.filter(state => state.abbrev === clinic.state)[0]
    if(selectedState){
      return selectedState.abbrev;
    } else if (enteredAbbreviation){
      return enteredAbbreviation.abbrev;
    } else {
      return "??"
    }
  }

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <div className={classes.titleContainer}>
            <div className={classes.circle}>
            <div className={classes.circleInner}>
              {stateAbbreviation()}
            </div>
          </div>
          <div className={classes.titleField}>
            <Typography variant="h1" className={classes.headerText} style={{marginBottom: "0 !important"}}>
              {clinic.title}
            </Typography>
          </div>
          {
            !smallBreakpoint &&
            renderButtonSection(clinic)
          }
        </div>
        <div>
          <div className='html__insert' dangerouslySetInnerHTML={{__html: clinic.introMessage}}></div>
        </div>
        {
          smallBreakpoint &&
          renderButtonSection(clinic)
        }
        <Grid container>
          <Grid item xs={12} sm={6} className={classes.grid}>
            <div className={classes.cardField}>
              <span className={clsx(classes.icon, "icon-find")}></span>
              <Typography variant="h6">
                <div className={classes.htmlCardField} dangerouslySetInnerHTML={{__html: clinic.address}}></div>
              </Typography>
            </div>
            <div className={classes.cardField}>
              <span className={clsx(classes.icon, "icon-wait")}></span>
              <Typography variant="h6">
                <div className={clsx(classes.htmlCardField )} style={{alignSelf: "center"}} dangerouslySetInnerHTML={{__html: clinic.hours}}></div>
              </Typography>
            </div>
            <div className={classes.cardField}>
              <span className={clsx(classes.icon,"icon-print")}></span>
              <Typography variant="h6">
                {clinic.fax}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.grid}>
            <div className={classes.cardField}>
              <span className={clsx(classes.icon, "icon-call")}></span>
              <Typography variant="h6">
                {clinic.phone}
              </Typography>
            </div>
            {
              clinic.facebookUrl &&
              <div className={classes.cardField}>
                <span className={clsx(classes.icon, "icon-facebook-official")}></span>
                <Typography variant="h6">
                  <a href={`${clinic.facebookUrl}`} target="_blank" rel="noopener" style={{textDecoration: "none", color: Colors.azure}}>
                    Like us on Facebook
                  </a>
                </Typography>
              </div>
            }
            {
              clinic.googleUrl &&
              <div className={classes.cardField}>
                <span className={clsx(classes.icon, "icon-google")}></span>
                <Typography variant="h6">
                  <a href={`${clinic.googleUrl}`} target="_blank" rel="noopener" style={{textDecoration: "none", color: Colors.azure}}>
                    Leave a review
                  </a>
                </Typography>
              </div>
            }

          </Grid>
          <Grid item xs={12}>
            <div>
              <Typography variant="body1">
                <div className="html__insert" style={{alignSelf: "center"}} dangerouslySetInnerHTML={{__html: clinic.description}}></div>
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Card>
      {(clinic.directionsImage || clinic.directionsTitle || clinic.directionsBody) &&
      <div className={contentContainerClasses.noOverlap}>
        <Card
          className={classes.card}
          classes={{
            card: `${classes.card}`
          }}
          overrideIdentifier="card-container-identifier"
        >
          <FullWidthImageText
            widget={{
              template: "Large",
              image: clinic.directionsImage,
              title: clinic.directionsTitle,
              body: clinic.directionsBody,
              useClinicPadding: true
            }}
          />
        </Card>
      </div>
      }
      {
        (clinicTeamMembers && clinicTeamMembers.length > 0) &&
      <div style={{marginBottom: withButton ? "50px" : 0}}>
        <div>
          <Typography variant="h2" style={{color: Colors.white, textAlign: "center", marginBottom: 44}}>
            Meet the Team
          </Typography>
          <div>
            <Grid container spacing={smallBreakpoint ? 0 : 2} className={clsx(classes.teamCardGrid, (smallBreakpoint && !withButton) ?
              classes.removeLastMarginBottom : null)}>
            {
              clinicTeamMembers.map(function(m){
                return(
                  <Grid item xs="12" md="4" justifyContent="center" className={clsx(classes.cardGridContainer, "profile_card_list")}>
                    <CircleProfileCard
                      italicBody
                      name={m.name}
                      jobTitle={m.jobTitle}
                      description={m.description}
                      image={m.photo}
                      profileCardClasses={{
                        imageContainer: classes.teamCardImageContainer,
                        image: classes.teamCardImage
                      }}
                    />
                  </Grid>
                )

              })
            }
            </Grid>
          </div>
        </div>
      </div>
      }
      {
        withButton &&
        <div style={{display: "flex", justifyContent: "center"}}>
          <Link to={"/clinics"} style={{textDecoration: "none"}}>
            <Button color="secondary">
              <Typography variant="button">Back</Typography>
            </Button>
          </Link>
        </div>
      }
    </div>
  )
}
