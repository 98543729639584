import React from 'react'
import { graphql } from 'gatsby'
import ClinicDetailView from "../views/ClinicDetailView"
import ThemeProvider from "@material-ui/styles/ThemeProvider"
import theme from "../constants/Theme"
export default ({ data: {clinic} }) => (
  <ThemeProvider theme={theme}>
    <ClinicDetailView clinic={clinic} />
  </ThemeProvider>
);

export const query = graphql`
    query ClinicQuery($id: String!) {
        clinic: datoCmsClinic(id: { eq: $id }) {
            ...Clinic
        }
    }
`
