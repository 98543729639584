import React from "react"

import Map from "./map"
import { fromJS } from "immutable"
import useWindowDimensions from "../hooks/useWindowDimensions"

export default function SingularMapSection({data, state}){
  return(
    <div className="singular-map-section" style={{height: "404px", width: "100%"}}>
      <Map
        singular={true}
        data={fromJS(data)}
        selectedCoordinates={{coordinates: [fromJS(data).getIn([0, 'location', 'longitude']), fromJS(data).getIn([0, 'location', 'latitude'])]}}
        //notice: +1 latitude hack above to prevent card's offet from covering tooltip
      />
    </div>
  )
}